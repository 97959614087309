/**
 * Button type
 */
export enum TypeBaseButton {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Simple = 'simple',
  Square = 'square',
  Round = 'round',
}

/**
 * Button variant
 */
export enum ViewBaseButton {
  Simple = 'simple',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  White = 'white',
  Red = 'red',
  Transparent = 'transparent-10',
}
